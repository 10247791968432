<template>
	<div class="d-flex justify-center">
		<div v-if="type !== 'haha'" class="emoji  emoji--sad">
			<div class="emoji__face">
				<div class="emoji__eyebrows"></div>
				<div class="emoji__eyes"></div>
				<div class="emoji__mouth"></div>
			</div>
		</div>
		<div v-else class="emoji  emoji--haha">
			<div class="emoji__face">
				<div class="emoji__eyes"></div>
				<div class="emoji__mouth">
					<div class="emoji__tongue"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: () => 'haha'
		}
	}
}
</script>
<style lang="scss" scoped>
$emoji-base-color: #ffda6a;
$emoji-like-color: #548dff;
$emoji-love-color: #f55064;
$emoji-angry-color: #d5234c;
$emoji-white-color: #ffffff;
$emoji-black-color: #000000;
$emoji-text-color: #8a8a8a;

body {
	margin: 0;
	width: 1200px;
	max-width: 100%;
	text-align: center;
	font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.emoji {
	width: 120px;
	height: 120px;
	margin: 15px 15px 40px;
	background: $emoji-base-color;
	display: inline-block;
	border-radius: 50%;
	position: relative;

	&:after {
		position: absolute;
		bottom: -40px;
		font-size: 18px;
		width: 60px;
		left: calc(50% - 30px);
		color: $emoji-text-color;
	}
}

.emoji__face,
.emoji__eyebrows,
.emoji__eyes,
.emoji__mouth,
.emoji__tongue,
.emoji__heart,
.emoji__hand,
.emoji__thumb {
	position: absolute;

	&:before,
	&:after {
		position: absolute;
		content: '';
	}
}

.emoji__face {
	width: inherit;
	height: inherit;
}

.emoji--haha {
	.emoji__face {
		animation: haha-face 2s linear infinite;
	}

	.emoji__eyes {
		width: 26px;
		height: 6px;
		border-radius: 2px;
		left: calc(50% - 13px);
		top: 35px;
		transform: rotate(20deg);
		background: transparent;
		box-shadow: -25px 5px 0 0 $emoji-black-color,
			25px -5px 0 0 $emoji-black-color;

		&:after {
			left: 0;
			top: 0;
			width: 26px;
			height: 6px;
			border-radius: 2px;
			transform: rotate(-40deg);
			background: transparent;
			box-shadow: -25px -5px 0 0 $emoji-black-color,
				25px 5px 0 0 $emoji-black-color;
		}
	}

	.emoji__mouth {
		width: 80px;
		height: 40px;
		left: calc(50% - 40px);
		top: 50%;
		background: $emoji-black-color;
		border-radius: 0 0 40px 40px;
		overflow: hidden;
		z-index: 1;
		animation: haha-mouth 2s linear infinite;
	}

	.emoji__tongue {
		width: 70px;
		height: 30px;
		background: $emoji-love-color;
		left: calc(50% - 35px);
		bottom: -10px;
		border-radius: 50%;
	}
}

.emoji--sad {
	.emoji__face {
		animation: sad-face 2s ease-in infinite;
	}

	.emoji__eyebrows {
		left: calc(50% - 3px);
		top: 35px;
		height: 6px;
		width: 6px;
		border-radius: 50%;
		background: transparent;
		box-shadow: -40px 9px 0 0 $emoji-black-color, -25px 0 0 0 $emoji-black-color,
			25px 0 0 0 $emoji-black-color, 40px 9px 0 0 $emoji-black-color;

		&:before,
		&:after {
			width: 30px;
			height: 20px;
			border: 6px solid $emoji-black-color;
			box-sizing: border-box;
			border-radius: 50%;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			top: 2px;
			left: calc(50% - 15px);
		}

		&:before {
			margin-left: -30px;
			transform: rotate(-30deg);
		}

		&:after {
			margin-left: 30px;
			transform: rotate(30deg);
		}
	}
	.emoji__eyes {
		width: 14px;
		height: 16px;
		left: calc(50% - 7px);
		top: 50px;
		border-radius: 50%;
		background: transparent;
		box-shadow: 25px 0 0 0 $emoji-black-color, -25px 0 0 0 $emoji-black-color;

		&:after {
			background: $emoji-like-color;
			width: 12px;
			height: 12px;
			margin-left: 6px;
			border-radius: 0 100% 40% 50% / 0 50% 40% 100%;
			transform-origin: 0% 0%;
			animation: tear-drop 2s ease-in infinite;
		}
	}

	.emoji__mouth {
		width: 60px;
		height: 80px;
		left: calc(50% - 30px);
		top: 80px;
		box-sizing: border-box;
		border: 6px solid $emoji-black-color;
		border-radius: 50%;
		border-bottom-color: transparent;
		border-left-color: transparent;
		border-right-color: transparent;
		background: transparent;
		animation: sad-mouth 2s ease-in infinite;

		&:after {
			width: 6px;
			height: 6px;
			background: transparent;
			border-radius: 50%;
			top: 4px;
			left: calc(50% - 3px);
			box-shadow: -18px 0 0 0 $emoji-black-color, 18px 0 0 0 $emoji-black-color;
		}
	}
}
@keyframes haha-face {
	10%,
	30%,
	50% {
		transform: translateY(25px);
	}
	20%,
	40% {
		transform: translateY(15px);
	}
	60%,
	80% {
		transform: translateY(0);
	}
	70%,
	90% {
		transform: translateY(-10px);
	}
}

@keyframes sad-face {
	25%,
	35% {
		top: -15px;
	}
	55%,
	95% {
		top: 10px;
	}
	100%,
	0% {
		top: 0;
	}
}

@keyframes sad-mouth {
	25%,
	35% {
		transform: scale(0.85);
		top: 70px;
	}
	55%,
	100%,
	0% {
		transform: scale(1);
		top: 80px;
	}
}

@keyframes tear-drop {
	0%,
	100% {
		display: block;
		left: 35px;
		top: 15px;
		transform: rotate(45deg) scale(0);
	}
	25% {
		display: block;
		left: 35px;
		transform: rotate(45deg) scale(2);
	}
	49.9% {
		display: block;
		left: 35px;
		top: 65px;
		transform: rotate(45deg) scale(0);
	}
	50% {
		display: block;
		left: -35px;
		top: 15px;
		transform: rotate(45deg) scale(0);
	}
	75% {
		display: block;
		left: -35px;
		transform: rotate(45deg) scale(2);
	}
	99.9% {
		display: block;
		left: -35px;
		top: 65px;
		transform: rotate(45deg) scale(0);
	}
}

@keyframes haha-mouth {
	10%,
	30%,
	50% {
		transform: scale(0.6);
		top: 45%;
	}
	20%,
	40% {
		transform: scale(0.8);
		top: 45%;
	}
	60%,
	80% {
		transform: scale(1);
		top: 50%;
	}
	70% {
		transform: scale(1.2);
		top: 50%;
	}
	90% {
		transform: scale(1.1);
		top: 50%;
	}
}
</style>
