<template>
	<ForgotPassword />
</template>
<script>
import ForgotPassword from '@/modules/forgotPassword/ForgotPassword'
export default {
	components: {
		ForgotPassword
	}
}
</script>
