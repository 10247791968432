<template>
  <div>
    <div class="headline font-weight-bold primary--text text-center">
      {{ currentRegistration.title }}
    </div>
    <div class="d-flex justify-center rounded-0">
      <span class="caption grey--text"
        >{{ currentRegistration.subText }}
        <a class="primary--text" @click="$emit('onClick')">{{
          currentRegistration.buttonText
        }}</a>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'signIn'
    }
  },
  computed: {
    currentRegistration() {
      return this.listRegistration[this.type]
    }
  },
  data() {
    return {
      listRegistration: {
        signIn: {
          key: 'signIn',
          title: 'Đăng nhập',
        },
        forgotPassword: {
          key: 'forgotPassword',
          title: 'Quên mật khẩu ?',
          subText: 'Quay lại trang',
          buttonText: 'Đăng nhập'
        }
      }
    }
  }
}
</script>
