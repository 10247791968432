<template>
	<v-card class="rounded-0 elevation-0 pa-8" height="100%">
		<div class="d-flex flex-column justify-space-between">
			<div>
				<TitleForm
					type="forgotPassword"
					@onClick="$router.push('/auth/signIn')"
				/>
				<v-card-text class="pa-8 rounded-0">
					<Emoji type="sad" />
					<div class="text-center primary--text">
						<div>
							Vui lòng liên hệ <strong>0366311292</strong> ( Mr Huy ) <br />
							hoặc gửi mail đến <strong>support@izteach.vn</strong> để được hỗ
							trợ
						</div>
					</div>
					<div class="d-flex justify-center pa-8">
						<v-btn
							fab
							dark
							small
							depressed
							@click="$router.push('/auth/signIn')"
							color="primary"
						>
							<v-icon dark> mdi-arrow-left</v-icon>
						</v-btn>
					</div>
				</v-card-text>
			</div>
		</div>
	</v-card>
</template>
<script>
import Emoji from '@/components/animation/Emoji'
import TitleForm from '@/modules/formInformation/TitleForm'
export default {
	components: {
		TitleForm,
		Emoji
	}
}
</script>
